import React from "react";
import styled from "styled-components";

const SVG = styled.svg`
	background: transparent;
	fill: currentColor;
`;

const CancelIcon = () => {
	return (
		<SVG
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 24 24"
			className="cancel-icon"
		>
			<path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm4.207 12.793-1.414 1.414L12 13.414l-2.793 2.793-1.414-1.414L10.586 12 7.793 9.207l1.414-1.414L12 10.586l2.793-2.793 1.414 1.414L13.414 12l2.793 2.793z"></path>
		</SVG>
	);
};

export default CancelIcon;
